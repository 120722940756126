import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AuthInterceptor} from "./auth-interceptor";

const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: interceptors
})
export class InterceptorModule {
}
