import {Component, OnInit} from '@angular/core';
import {CredentialsService} from "../../core/service/credentials.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    public logged = true

    constructor(private credentialService: CredentialsService) {
    }

    async ngOnInit() {
        this.logged = this.credentialService.isLoggedIn()
    }

}
