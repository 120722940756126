import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {lastValueFrom} from 'rxjs';
import {Ticket, TicketLogged} from "../model/ticket.model";

@Injectable({
    providedIn: 'root',
})
export class TicketService {
    private readonly baseUrl: string = environment.API_BASE_URL;
    private token: string | null = localStorage.getItem('jwt');

    constructor(private http: HttpClient) {
    }

    public async createTicket(ticketData: Ticket | TicketLogged): Promise<void> {
        if (!this.token) {
            const response = this.http.post<void>(`${this.baseUrl}/tickets`, ticketData)
            return await lastValueFrom(response);
        }
        const response = this.http.post<void>(`${this.baseUrl}/tickets`, ticketData);
        try {
            return await lastValueFrom(response);
        } catch (error) {
            console.error('Errore durante l\'invio del ticket.');
        }
    }

    public async getTicket(ticketId: string): Promise<any> {
        const response = this.http.get(`${this.baseUrl}/tickets/${ticketId}`);
        return await lastValueFrom(response);
    }

    public async getTickets(): Promise<any> {
        const response = this.http.get(`${this.baseUrl}/tickets`);
        return await lastValueFrom(response)
    }

    public async updateTicket(ticketId: string, ticketData: any): Promise<any> {
        const response = this.http.patch(`${this.baseUrl}/tickets/${ticketId}`, ticketData);
        return await lastValueFrom(response)
    }
}
