<div class="d-flex flex-column p-4">
    <div class="title" i18n>Sei vendendo il tuo veicolo?</div>
    <div i18n><b>Inizia subito, registrati al servizio</b> e tutela la vendita del tuo veicolo!</div>

    <button (click)="goTo('onboarding', true)" class="agos-button btn mx-auto my-4" i18n id="landing-seller-onboarding">
        Registrati ora
    </button>
    <hr class="shadow">

    <div class="box my-4">
        <div *ngFor="let explain of howItWorks, let i = index" [ngClass]="i+1!=howItWorks.length? 'mb-4':''"
             class="d-flex flex-nowrap">
            <div class="me-3" style="width: 35px!important; height: 35px!important;">
                <div class="list-number my-auto">{{ i + 1 }}</div>
            </div>
            <div class="my-auto">{{ explain }}</div>
        </div>
    </div>

    <div class="title" i18n>Perché scegliere di registrarsi al servizio?</div>
    <div i18n>Perché garantisce la protezione del denaro al 100% ed azzera i rischi di frode.</div>

    <div class="title" i18n>Qual è il costo del servizio di tutela dei pagamenti?</div>
    <div>
        <span i18n>
                    Il servizio è gratuito per un periodo di tempo limitato. L’acquirente pagherà solo le commissioni relative al metodo di pagamento scelto per versare l’importo concordato per la compravendita.
        </span>
        <button (click)="goTo('landing/faq')" class="font20 text-start fw-semibold btn btn-outline-link"
                i18n id="landing-seller-goToFaq"
                style="padding: 0rem">Scopri di più, vai alle FAQ
        </button>
    </div>
    <hr>
    <!--app-personal-loan></app-personal-loan-->
</div>