export enum ReasonEnum {
    payment_method = "payment_method",
    insert_or_modify_data = "insert_or_modify_data",
    price_management = "price_management",
    forward_funds = "forward_funds",
    homebanking_login = "homebanking_login",
    payment_management = "payment_management",
    other = "other",
}

export interface Ticket {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    description?: string;
    reason?: ReasonEnum;
    marketingConsent: boolean;
    profilingConsent: boolean;
}

export interface TicketLogged {
    reason?: ReasonEnum;
    description?: string;
}
