import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ConsumerModel} from "../model/consumer.model";

@Injectable({
    providedIn: 'root',
})

export class ConsumerService {
    private readonly baseUrl: string = environment.API_BASE_URL;

    constructor(private http: HttpClient) {
    }

    public async getConsumer(): Promise<ConsumerModel> {
        const url = `${this.baseUrl}/consumers`;
        let response = this.http.get<ConsumerModel>(url);
        return await lastValueFrom(response);
    }

    public async updateConsumer(phone: string): Promise<ConsumerModel> {
        const url = `${this.baseUrl}/consumers`;
        const payload = {phone: phone}; // ???
        let response = this.http.patch<ConsumerModel>(url, payload);
        return await lastValueFrom(response);
    }


    public async createConsumer(consumerData: ConsumerModel): Promise<ConsumerModel> {
        const url = `${this.baseUrl}/consumers`;
        const response = this.http.post<ConsumerModel>(url, consumerData);
        return await lastValueFrom(response);
    }

    public async deleteConsumer(consumerId: string): Promise<void> {
        const url = `${this.baseUrl}/consumers/${consumerId}`;
        await this.http.delete(url).toPromise();
    }

    public async identify(order: string, identityTechnology: string, consumerID:string): Promise<void> {
        window.location.href = `${this.baseUrl}/consumers/identification?identityTechnology=${identityTechnology}&consumerID=${consumerID}&order=${order}`;
    }
}
