import {Component, ElementRef, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from 'rxjs/operators';
import {CredentialsService} from "../../../core/service/credentials.service";

@Component({
    selector: 'app-bottom-navbar',
    templateUrl: './bottom-navbar.html',
    styleUrls: ['./bottom-navbar.css']
})
export class BottomNavbar implements OnInit {
    public logged: boolean = true

    constructor(
        private router: Router,
        private authService: CredentialsService,
        private elRef: ElementRef) {
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.elRef.nativeElement.ownerDocument.body.scrollTop = 0;
        });
        this.logged = this.authService.isLoggedIn()
    }

    public async goTo() {
        this.authService.cleanAuthStorage()
        await this.router.navigate(['onboarding']);
    }
}
