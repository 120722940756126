import {Component, HostListener, OnInit} from '@angular/core';
import {SocketService} from "./core/service/socket.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {ClickEvent, NewSocketSession, WSRouter} from "./core/model/socket.model";
import {ConsumerService} from "./core/service/consumer.service";
import {CredentialsService} from "./core/service/credentials.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    private socketSession?: string;

    constructor(
        private consumerService: ConsumerService,
        private credentialsService: CredentialsService,
        private activatedRoute: ActivatedRoute,
        private socketService: SocketService,
        private router: Router,
    ) {
        let authToken: string | null = this.activatedRoute.snapshot.queryParamMap.get('jwt')
        if (!authToken) authToken = localStorage.getItem('jwt')
        if (authToken) localStorage.setItem('jwt', authToken)

        this.setupSocket(authToken || undefined).then()

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(event => {
                if (!event) return;
                const data = event as NavigationEnd;
                const userAgent = window.navigator.userAgent;
                const browserName = this.getBrowserName
                const browserVersion = this.getBrowserVersion
                const message: WSRouter = {
                    routerChange: {
                        url: data.urlAfterRedirects,
                        userAgent,
                        browserName,
                        browserVersion
                    }
                };
                this.socketService.send(message);
            })
    }

    private get getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }

    private get getBrowserVersion() {
        const userAgent: any = navigator.userAgent;
        let tempMatch: any;
        let versionMatch: any =
            userAgent.match(
                /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
            ) || [];
        if (/trident/i.test(versionMatch[1])) {
            tempMatch = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (tempMatch[1] || '');
        }
        if (versionMatch[1] === 'Chrome') {
            tempMatch = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (tempMatch != null) {
                return tempMatch.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }
        versionMatch = versionMatch[2]
            ? [versionMatch[1], versionMatch[2]]
            : [navigator.appName, navigator.appVersion, '-?'];
        if ((tempMatch = userAgent.match(/version\/(\d+)/i)) != null)
            versionMatch.splice(1, 1, tempMatch[1]);
        return versionMatch;
    }

    @HostListener('document:click', ['$event'])
    @HostListener('input', ['$event'])
    documentClick(event: { target: HTMLInputElement; }): void {
        if (!(event instanceof PointerEvent || event instanceof MouseEvent || event instanceof InputEvent)) return
        if (!event.target.id) return

        if (!(event instanceof InputEvent)) {
            const data: ClickEvent = {clickEvent: {id: event.target.id, x: event.clientX, y: event.clientY}};
            this.socketService.send(data)
        }
    }

    async setupSocket(jwt?: string) {
        this.socketService.observeEvent<NewSocketSession>('new-session').subscribe(event => {
            this.socketSession = event.sessionID
            localStorage.removeItem("socketSession")
            localStorage.setItem('socketSession', event.sessionID)
        })

        this.socketSession = localStorage.getItem('socketSession') || undefined
        this.socketService.onClose().subscribe(() => {
            setTimeout(() => {
                this.socketService.connect(false, this.socketSession, jwt)
            }, 3000);
        })
        await this.socketService.connect(true, this.socketSession, jwt)
    }

    async ngOnInit() {
        try {
        await this.consumerService.getConsumer()
        } catch (e){
            this.credentialsService.cleanAuthStorage()
        }
    }
}
