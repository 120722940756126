import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-question-answer',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FaqComponent implements AfterViewInit {
    public text: string = ""
    public faq: { question: string, answer: string }[] = [
        {
            question: $localize`Chi è Agos?`,
            answer: $localize`Agos è una società finanziaria leader nel settore del credito al consumo, presente in Italia da oltre trent’anni. Sostiene la realizzazione dei progetti dei propri clienti e supporta le vendite dei partner nei diversi mercati attraverso l’offerta di prestiti personali, finanziamenti finalizzati, carte di credito, cessione del quinto stipendio, leasing e un’ampia gamma di servizi assicurativi.`
        },
        {
            question: $localize`Chi è FlowPay?`,
            answer: $localize`FlowPay è un istituto di pagamento autorizzato da Banca d'Italia con un’esperienza consolidata nello sviluppo di soluzioni in grado di efficientare i flussi di incasso e di pagamento per aziende e privati. Costruisce soluzioni digitali per i pagamenti sicuri ed integrati con piattaforme online.`
        },
        {
            question: $localize`Come funziona il servizio?`,
            answer: $localize`Il servizio mette insieme l’affidabilità di Agos nel settore del credito al consumo e l’esperienza di FlowPay in quello dei servizi di pagamento per offrirti la serenità che meriti nella compravendita di veicoli. Con il servizio di tutela dei pagamenti, infatti, potrai approcciare al mercato privato dell'usato in totale sicurezza, come? L’acquirente verserà l’importo concordato per la compravendita sul conto corrente sicuro gestito da FlowPay presso una primaria banca italiana e, solo al termine del passaggio di proprietà, potrà autorizzare il trasferimento del denaro al venditore. Nel caso in cui la compravendita del veicolo non vada a buon fine, l’acquirente riceverà nuovamente l’importo eventualmente versato, proteggendosi dal rischio di imbattersi in truffe o raggiri. Il servizio di tutela dei pagamenti, inoltre, ti consente di interagire solo con utenti registrati e verificati, garantendoti quindi l’identificazione della controparte.`
        },
        {
            question: $localize`Qual è il costo del servizio?`,
            answer: $localize`Il servizio è gratuito per un periodo di tempo limitato. L’acquirente pagherà solo le commissioni relative al metodo di pagamento scelto per versare l’importo concordato per la compravendita.`
        },
        {
            question: $localize`Quali sono i metodi di pagamento accettati?`,
            answer: $localize`I metodi di pagamento accettati sono: <br>•   Pagamento con bonifico manuale,<br>•   Pagamento con carta di credito/debito (solo di tipo consumer), <br>•   Pagamento integrato con la banca (Pay by Bank), che permette di inizializzare un bonifico direttamente dal sito, utilizzando le credenziali e il sistema di autorizzazione sicuro della propria banca. `
        },
        {
            question: $localize`Quali sono le commissioni relative al metodo di pagamento?`,
            answer: $localize`Le commissioni sono a carico dell’acquirente e si applicano sull’importo concordato nella compravendita.<br>•   Pagamento con bonifico manuale: 0,5% <br>•   Pagamento con Carta di credito/debito (solo di tipo consumer): 1,3%  <br>•   Pagamento Pay by Bank: 0,4`
        },
        {
            question: $localize`In cosa consiste l’identificazione?`,
            answer: $localize`Potrai identificarti accedendo a SPID – il Sistema Pubblico di Identità Digitale – oppure tramite video-selfie, modalità di identificazione che si avvale di una tecnologia che consente, con un alto grado di accuratezza, di confrontare il volto presente nel video con la foto del documento di identità. L’identificazione è richiesta dalla normativa antiriciclaggio, con l’attuazione della Direttiva UE 2015/849, recepita in Italia tramite il Decreto Legislativo 90/2017. L’identificazione è inoltre necessaria per tutelare consumatori e aziende da frodi online, corruzione e pratiche di riciclaggio di denaro.`
        },
        {
            question: $localize`Ci si può registrare con dati diversi da quelli dell’acquirente o del venditore?`,
            answer: $localize`È obbligatorio che i dati anagrafici forniti per la registrazione del profilo – nome, cognome e codice fiscale – siano i medesimi dei soggetti coinvolti in prima persona nell’operazione di compravendita. Inoltre, assicurati di inserire il tuo indirizzo e-mail e il tuo numero di cellulare corretti, in quanto riceverai a tali recapiti le comunicazioni per finalizzare la registrazione e tutelare le tue operazioni di compravendita.`
        },
        {
            question: $localize`Cosa succede se la compravendita non va a buon fine?`,
            answer: $localize`Nel caso in cui la compravendita non vada a buon fine e l’acquirente abbia già versato l’importo sul conto FlowPay, , l’operazione verrà annullata e l’acquirente riceverà il rimborso tramite un bonifico pari all’intero l’importo versato.`
        },
        {
            question: $localize`In cosa consiste la registrazione?`,
            answer: $localize`La registrazione consiste in pochi semplici passi: ti basterà fornire i tuoi dati anagrafici - nome, cognome e codice fiscale - e i tuoi recapiti - indirizzo e-mail e numero di cellulare. Ti verrà richiesto infine di creare una password sicura che, insieme al tuo indirizzo e-mail, ti servirà per accedere al servizio. Una volta registrato, non c’è nessun vincolo all’utilizzo del servizio.`
        },
        {
            question: $localize`Quali veicoli possiamo tutelare?`,
            answer: $localize`E’ possibile tutelare la compravendita di qualsiasi tipo di veicolo: autoveicolo, motoveicolo o altri tipi di veicoli (ad es. camper).`
        },
        {
            question: $localize`Quali dati sono necessari per iniziare ad utilizzare il servizio?`,
            answer: $localize`Una volta registrato al servizio, ti basterà creare la tua operazione di compravendita indicando i dati del veicolo che stai vendendo o acquistando – tipo, modello e targa del veicolo – e l’importo concordato. Dopodiché potrai procedere invitando la controparte a partecipare all’operazione in modo da proseguire con le attività per l’identificazione delle parti e per il pagamento dell’importo.`
        },
        {
            question: $localize`Come invito la controparte a partecipare?`,
            answer: $localize`Una volta creata l’operazione (di vendita o acquisto) sarà necessario invitare la controparte per proseguire, come? Ti basterà inserire il suo indirizzo e-mail: la controparte riceverà una mail alla sua casella di posta con l’invito a partecipare all’operazione, verificare i dati da te inseriti e registrarsi al servizio, qualora non lo abbia già fatto prima.`
        },
        {
            question: $localize`Cosa succede se la controparte non accetta di partecipare all’operazione?`,
            answer: $localize`Se la controparte non accetta di partecipare all’operazione, potrà essere cancellata o, se sei il venditore, potrai decidere di invitare un altro acquirente, modificando l’indirizzo e-mail ed inviando nuovamente la richiesta di invito a partecipare.`
        },
        {
            question: $localize`Cosa succede se la controparte non conferma i dati dell’operazione?  `,
            answer: $localize`Nel caso in cui la controparte non confermasse i dati dell’operazione, potrà essere cancellata o, se sei il venditore, potrai decidere di invitare un altro acquirente, modificando l’indirizzo e-mail ed inviando nuovamente la richiesta di invito a partecipare.`
        },
        {
            question: $localize`Cosa succede se la controparte non conferma l'importo dell’operazione?`,
            answer: $localize`Nel caso in cui la controparte non confermasse l'importo dell’operazione, potrà essere cancellata o potrai decidere di modificare il dato ed invitare nuovamente la controparte a partecipare con l’importo aggiornato.`
        },
        {
            question: $localize`Quando e come verrà pagato il venditore?`,
            answer: $localize`Il venditore verrà pagato tramite bonifico sul suo conto corrente quando l’acquirente confermerà di aver effettuato il passaggio di proprietà ed autorizzerà il trasferimento dal conto FlowPay. L’importo verrà trasferito sul conto corrente indicato dal venditore previa verifica della sua identità tramite SPID o video-selfie.`
        },
        {
            question: $localize`Posso annullare/cancellare l’operazione?`,
            answer: $localize`Puoi cancellare l’operazione in qualsiasi momento finché la controparte non accetta l’invito a partecipare. Una volta che la controparte conferma e diventa parte dell’operazione, non potrai più modificarla e quindi cancellarla in autonomia.<br>In ogni caso, se cambi idea, non sei obbligato a proseguire e potrai contattare l’assistenza affinchè l’operazione venga cancellata, oppure attendere la cancellazione automatica che avviene dopo trenta giorni dall’ultima attività effettuata da una delle due parti (venditore o acquirente).`
        },
    ]
    public filteredFAQ: { question: string, answer: string }[] = this.faq

    constructor(
        private router: Router,
        private elRef: ElementRef) {
    }

    ngAfterViewInit() {
        this.elRef.nativeElement.ownerDocument.body.scrollTop = 0;
    }

    goBack(): void {
        window.history.back();
    }

    public async goTo() {
        await this.router.navigate(['landing'])
    }

    public search(searchingFor: string) {
        if (searchingFor === "") {
            this.filteredFAQ = this.faq
            return
        }
        const keys = searchingFor.toLowerCase().split(' ');
        this.filteredFAQ = this.faq.filter((element) => {
            const question = element.question.toLowerCase();
            const answer = element.answer.toLowerCase();
            return keys.every(word => question.includes(word) || answer.includes(word));
        });
    }

}
