import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LandingComponent} from "./landing.component";
import {LandingRoutingModule} from "./landing.routing.module";
import {FaqComponent} from "./faq/faq.component";
import {BottomNavbar} from "./bottom-navbar/bottom-navbar";
import {LandingBuyerComponent} from "./landing-buyer/landing-buyer.component";
import {LandingSellerComponent} from "./landing-seller/landing-seller.component";
import {MainLandingComponent} from "./landing-generic/main-landing.component";
import {PersonalLoanComponent} from "../../shared/personal-loan/personal-loan.component";
import {TrustPilotComponent} from "./landing-generic/trust-pilot/trust-pilot.component";

@NgModule({
    declarations: [
        LandingComponent,
        FaqComponent,
        BottomNavbar,
        LandingBuyerComponent,
        MainLandingComponent,
        LandingSellerComponent],
    exports: [
        LandingBuyerComponent
    ],
  imports: [
    CommonModule,
    LandingRoutingModule,
    NgOptimizedImage,
    FormsModule,
    ReactiveFormsModule,
    PersonalLoanComponent,
    TrustPilotComponent,
  ]
})
export class LandingModule {
}
