import {Component} from '@angular/core';
import {FormsModule} from "@angular/forms";

@Component({
    selector: 'app-personal-loan',
    standalone: true,
    imports: [
        FormsModule
    ],
    templateUrl: './personal-loan.component.html',
    styleUrl: './personal-loan.component.css'
})
export class PersonalLoanComponent {
    public amount = 5000
}
