<div class="mt-auto d-flex flex-column w-100 text-white p-md-4 p-2 agos-background text-center">
    <div class="d-flex flex-nowrap mx-auto mt-4">
        <div class="d-flex flex-column me-3">
            <div class="fw-semibold text-center"> Promoted by</div>
            <img class="mx-auto" height="25" src="../../../assets/agos-white-logo.png" width="65"/>
        </div>

        <div class="d-flex flex-column ms-3">
            <div class="fw-semibold text-center"> Powered by</div>
            <img class="ms-auto" height="25" src="../../../assets/flowpay-logo.png" width="140"/>
        </div>
    </div>
    <div class="font12 mt-3">P.IVA 08570720154</div>
    <div class="font12">©2023 Agos Ducato S.p.A.- Tutti i diritti riservati</div>
    <div class="d-md-block d-none" *ngIf="!logged" style="height: 6.7rem"></div>
    <div class="d-md-none" *ngIf="!logged" style="height: 5.7rem"></div>
</div>
