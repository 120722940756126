import {inject} from '@angular/core';
import {ResolveFn, Router} from '@angular/router';
import {OnboardingService} from "./service/onboarding.service";

export const disclaimerResolver: ResolveFn<void> = async () => {
  const service = inject(OnboardingService)
  const router = inject(Router)
  try {
    await service.getDisclaimer()
  } catch (error) {
    await router.navigate(['error'])
  }
}
