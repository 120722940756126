<app-bottom-navbar></app-bottom-navbar>
<div class="d-flex flex-column p-md-4 p-4 p-md-2">
  <div class="title" i18n>Acquista o vendi il tuo veicolo in tutta serenità</div>
  <div class="font20" i18n>Agos presenta il nuovo servizio di FlowPay di tutela dei pagamenti.</div>
  <b class="font20" i18n>Scopri come funziona</b>
  <div class="box my-4">
    <div *ngFor="let explain of howItWorks, let i = index" [ngClass]="i+1!=howItWorks.length? 'mb-4':''"
         class="d-flex flex-nowrap">
      <div class="me-3" style="width: 35px!important; height: 35px!important;">
        <div class="list-number my-auto">{{ i + 1 }}</div>
      </div>
      <div class="my-auto">{{ explain }}</div>
    </div>
  </div>

  <hr>

  <div class="title" i18n>Perché scegliere di registrarsi al servizio?</div>
  <div class="font20" i18n>Perché garantisce la protezione del denaro ed azzera i rischi.</div>
  <b class="font20" i18n>Scopri quali sono i vantaggi</b>
  <div class="my-4">
    <div *ngFor="let explain of whySignIn, let i = index" [ngClass]="i+1!=whySignIn.length? 'mb-4':''"
         class="d-flex flex-column outline-box p-4 py-5">
      <div class="font25 mx-auto fw-semibold">{{ explain.title }}</div>
      <img [alt]="'x'" [src]="explain.icon" class="mx-auto icon my-4">
      <div *ngFor="let text of explain.text" [innerHTML]="text">
      </div>
    </div>
  </div>
  <hr>
  <div class="title" i18n>Qual è il costo del servizio di tutela dei pagamenti?</div>
  <div class="font20" i18n>
    Il servizio è gratuito per un periodo di tempo limitato. L’acquirente pagherà solo le commissioni relative al metodo
    di pagamento scelto per versare l’importo concordato per la compravendita.
  </div>
  <button (click)="goTo('faq')" id="mainLanding-goToFaq" class="font20 text-start fw-semibold btn btn-outline-link" i18n
          style="padding: 0rem">
    Scopri di più, vai alle FAQ
  </button>

  <hr>
  <!--app-personal-loan></app-personal-loan-->

  <app-trust-pilot id="trustpilot"></app-trust-pilot>

</div>
