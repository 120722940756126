<div class="min-h-100 d-flex flex-column p-4">
    <div class="d-flex flex-wrap my-auto border font25">
        <div class="my-auto mx-auto d-flex flex-column">
            <div class="text-center">404</div>
            <div class="font12 fw-light">not found!</div>
        </div>
    </div>
    <div class="mb-auto text-center d-flex flex-column">
        <span>Quello che stai cercando non è qui, torna sulla nostra homepage!</span>
        <i class="ph-bold ph-arrow-left font35 mt-3" style="cursor: pointer; color: #07ABB8" (click)="goBack()"></i>
    </div>
</div>