import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, lastValueFrom, Observable} from 'rxjs';
import {environment} from "../../../environments/environment";
import {ConsumerModel} from "../model/consumer.model";
import {DisclaimerModel} from "../model/disclaimer.model";

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  public consumer$: BehaviorSubject<ConsumerModel | undefined> = new BehaviorSubject<ConsumerModel | undefined>(undefined);
  public disclaimer$: BehaviorSubject<DisclaimerModel | undefined> = new BehaviorSubject<DisclaimerModel | undefined>(undefined);
  private readonly baseUrl: string = environment.API_BASE_URL;

  constructor(private http: HttpClient) { }

  async getDisclaimer(): Promise<DisclaimerModel> {
    const response = this.http.get<DisclaimerModel>(`${this.baseUrl}/disclaimers/valid`);
    const disclaimer = await lastValueFrom(response)
    this.disclaimer$.next(disclaimer);
    return disclaimer;
  }

  async sendOTP(email: string, tin: string, firstName?: string, lastName?: string): Promise<void> {
    const response = this.http.post<void>(`${this.baseUrl}/onboarding/otp`, {email, tin, firstName, lastName});
    return await lastValueFrom(response);
  }

  async verifyOTP(code: string): Promise<void> {
    const body = {
      email: this.consumer$.value?.email,
      code
    }
    const response = this.http.post<void>(`${this.baseUrl}/onboarding/otp/verify`, body);
    return await lastValueFrom(response);
  }

  public async submitCredentials(password: string): Promise<void> {
    let body = {
      email: this.consumer$.value?.email,
      password,
      disclaimerID: this.disclaimer$.value?.id,
      consumer: this.consumer$.value
    }
    const response = this.http.post<{ token: string }>(`${this.baseUrl}/onboarding/credentials`, body);
    const result = await lastValueFrom(response);
    localStorage.removeItem('jwt')
    localStorage.setItem('jwt', result.token);
  }
}
