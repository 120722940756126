import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-trust-pilot',
  standalone: true,
  imports: [],
  templateUrl: './trust-pilot.component.html',
  styleUrl: './trust-pilot.component.css'
})
export class TrustPilotComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
    const trustboxRef = document.getElementById('trustbox');
    // @ts-ignore
    window.Trustpilot.loadFromElement(trustboxRef);
  }
}
