import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CredentialsService} from "../../../core/service/credentials.service";

@Component({
    selector: 'app-seller-landing',
    templateUrl: './landing-seller.component.html',
    styleUrls: ['./landing-seller.component.css']
})
export class LandingSellerComponent implements OnInit {
    public howItWorks: string[] = [
        $localize`Acquirente e venditore si registrano al servizio`,
        $localize`L’acquirente versa l’importo concordato sul conto FlowPay`,
        $localize`Acquirente e venditore fanno il passaggio di proprietà`,
        $localize`Il venditore si identifica e incassa l’importo`,
    ]

    constructor(private router: Router, private loginService: CredentialsService) {
    }

    public async goTo(root: string, merge?: boolean) {
        if (merge) await this.router.navigate([root], {queryParamsHandling: "merge"})
        else await this.router.navigate([root])
    }

    async ngOnInit() {
        if (this.loginService.isLoggedIn()) await this.router.navigate(['dashboard'], {queryParamsHandling: "merge"})
    }
}
