import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {LoggerService} from "../logger.service";
import {SocketEvent, WSMetric} from "../model/socket.model";
import {environment} from "../../../environments/environment";

interface Event<T> {
  eventName: string;
  payload: T;
}

@Injectable({providedIn: 'root'})
export class SocketService {
  private eventSubject: Subject<Event<any>> = new Subject();
  private closeSubject: Subject<void> = new Subject();
  private apiURL: string = environment.API_BASE_URL.includes("http") ? environment.API_BASE_URL : location.origin + "/api"
  private socketBaseWS = this.apiURL.replace('http', 'ws')
  private socket?: WebSocket;
  private jwt?: string

  constructor(
    private logger: LoggerService
  ) {
  }

  public async connect(force: boolean, session?: string, jwt?: string): Promise<void> {
    if (this.socket && !force && (!jwt || this.jwt == jwt)) return Promise.resolve()

    if (this.socket) this.socket.close();

    this.socket = await this.create(session, jwt)
    this.jwt = jwt
  }

  public observeEvent<T>(type: string): Observable<T> {
    return this.eventSubject.asObservable().pipe(
      filter(event => type === event.eventName),
      filter(event => !!event.payload),
      map(event => event.payload as T),
      filter(event => !!event)
    );
  }

  public onClose(): Observable<void> {
    return this.closeSubject.asObservable()
  }

  public send(message: SocketEvent) {
    if (this.socket) {
      const metrics = this.getMetrics()
      this.socket.send(JSON.stringify({message: message, metrics: metrics}))
      this.logger.debug(['SocketService', 'Socket message sent', message])
    } else {
      this.logger.debug(['SocketService', 'Socket not connected', message])
    }
  }

  private getMetrics(): WSMetric {
    const userAgent = window.navigator.userAgent;
    const mobile = this.isMobile(userAgent.toLowerCase());
    const page = window.location.pathname + window.location.search;
    return {mobile, userAgent, page}
  }

  private isMobile(userAgentString: string): boolean {
    let regex = RegExp("(Mobile|Android|iPhone|iPod|iPad|Windows Phone|BlackBerry|Symbian|Opera Mini|IEMobile|Mobile Safari|Android)")
    return !!userAgentString.match(regex)
  }

  private async create(session?: string, jwt?: string): Promise<WebSocket> {
    let url = this.socketBaseWS
    url = url + "/sessions" + '?hello=world'
    if (session) url = url + "&session=" + session
    if (jwt) url = url + "&jwt=" + jwt
    console.log(url)
    return new Promise<WebSocket>(resolve => {
      const ws = new WebSocket(url);
      ws.onopen = (event) => {
        resolve(ws)
        ws.send(JSON.stringify({
          message: {
            "open": {}
          },
          metrics: this.getMetrics()
        }))
        this.logger.debug(['SocketService', 'Socket opened', event, url])
      }

      ws.onmessage = async (event) => {
        const data = event.data;
        this.logger.debug(['SocketService', 'Socket message', event])

        let message: any
        try {
          message = JSON.parse(data);
        } catch {
          return
        }

        this.logger.debug(['SocketService', 'Socket message parsed', message])
        if (!!message.error) {
          if (!session) throw new Error(message.error)
          this.socket = await this.create(session, jwt)
          ws.close()
        }
        this.eventSubject.next(message);
      };

      ws.onclose = (closed) => {
        this.socket = undefined;
        this.logger.debug(['SocketService', 'Socket closed', closed])
        this.closeSubject.next()
      };
    });
  }
}
