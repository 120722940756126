import {AfterViewInit, Component, ElementRef, HostListener, OnInit, TemplateRef} from '@angular/core';
import {ConsumerModel} from "../../core/model/consumer.model";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ConsumerService} from "../../core/service/consumer.service";
import {NgxSpinnerService} from "ngx-spinner";
import {CredentialsService} from "../../core/service/credentials.service";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit {
    public ready: boolean = false
    public consumer: ConsumerModel = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        tin: "",
    }
    public edit = false
    public edited = false
    public firstNumber = {...this.consumer}

    constructor(
        private elRef: ElementRef,
        private router: Router,
        private modalService: NgbModal,
        private consumerService: ConsumerService,
        private spinnerService: NgxSpinnerService,
        private credentialsService: CredentialsService) {
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.edit) $event.preventDefault(); // Evita che la pagina venga ricaricata mentre "edit" è attivo
    }

    async ngOnInit() {
        await this.spinnerService.show()
        this.consumer = await this.consumerService.getConsumer()
        if (!this.consumer.phone.includes('+')){
            this.consumer.phone ='+39'+this.consumer.phone
        }
        this.ready = true
        await this.spinnerService.hide()
    }

    ngAfterViewInit() {
        this.elRef.nativeElement.ownerDocument.body.scrollTop = 0;
    }

    public async editing() {
        if (this.checkNumber() && !this.edit && !this.edited) {
            this.edit = !this.edit;
            this.firstNumber = {...this.consumer}
        } else if (!this.edited) {
            if (this.firstNumber.phone != this.consumer.phone && this.checkNumber())
                try {
                    await this.consumerService.updateConsumer(this.consumer.phone)
                    this.edited = true
                    this.firstNumber = {...this.consumer}
                    this.edit = !this.edit;
                    setTimeout(() => {
                        this.edited = false;
                    }, 4000);
                } catch (e) {
                    if (this.edit) window.alert("Errore, non è stato possibile aggiornare.")
                }
            else if (this.checkNumber() && !this.edited) this.edit = !this.edit
        }
    }


    public checkNumber() {
        const regex = /^(?:\+39)?\d{9,13}$/;
        return regex.test(this.consumer.phone);
    }

    public async goTo(root1: string, root2?: string) {
        if (!root2) await this.router.navigate([root1])
        else await this.router.navigate([root1, root2])
    }

    public async openModal(editing?: TemplateRef<any>, leave?: TemplateRef<any>,) {
        if (this.edit) this.modalService.open(editing, {centered: true})
        else this.modalService.open(leave, {centered: true})
    }

    public async disconnect() {
        this.edit = false
        this.consumer.phone = this.firstNumber.phone
        this.credentialsService.cleanAuthStorage()
        window.location.reload()
        await this.router.navigate(['landing'])

    }

    public closeModal() {
        this.modalService.dismissAll()
    }

}
