import {NgModule} from "@angular/core";
import {ExtraOptions, RouterModule, Routes} from "@angular/router";
import {NotFoundComponent} from "./shared/not-found/not-found.component";
import {disclaimerResolver} from "./core/disclaimer.resolver";
import {GenericErrorComponent} from "./shared/generic-error/generic-error.component";
import {LoginGuard} from "./core/guard/login.guard";
import {LandingSellerComponent} from "./components/landing/landing-seller/landing-seller.component";
import {LandingBuyerComponent} from "./components/landing/landing-buyer/landing-buyer.component";

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full'
    },
    {
        path: 'landing-buyer',
        component: LandingBuyerComponent
    },
    {
        path: 'landing-seller',
        component: LandingSellerComponent
    },
    {
        path: 'landing',
        loadChildren: () => import(`././components/landing/landing.module`).then(m => m.LandingModule)
    },
    {
        path: 'onboarding',
        resolve: {
            disclaimerResolver
        },
        loadChildren: () => import(`././components/onboarding/onboarding.routes`)
    },
    {
        path: 'login',
        loadChildren: () => import(`././components/login/login.routes`)
    },
    {
        path: 'assistance',
        loadChildren: () => import(`././components/assistance/assistance.module`).then(m => m.AssistanceModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import(`././components/dashboard/dashboard.module`).then(m => m.DashboardModule),
        canActivate: [LoginGuard]
    },
    {
        path: 'profile',
        loadChildren: () => import(`././components/profile/profile.module`).then(m => m.ProfileModule),
        canActivate: [LoginGuard]
    },
    {
        path: "error",
        component: GenericErrorComponent
    },
    {path: '**', component: NotFoundComponent},
    {
        path: "not-found",
        component: NotFoundComponent
    },
];

const config: ExtraOptions = {
    useHash: false,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}


