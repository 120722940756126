import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authToken = localStorage.getItem('jwt');
        const socket = localStorage.getItem('socketSession');
        if (authToken) request = request.clone({setHeaders: {Authorization: `Bearer ${authToken}`}});
        if (socket) request = request.clone({setHeaders: {session: socket}});
        return next.handle(request);
    }
}
