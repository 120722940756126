import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {CredentialsService} from "../service/credentials.service";
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private router: Router, private authService: CredentialsService) {}

 async canActivate() {
    if (this.authService.isLoggedIn() && !this.authService.isTokenExpired()) {
      return true;
    } else {
     await this.router.navigate(['/login']);
      return false;
    }
  }
}
