import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LandingComponent} from "./landing.component";
import {FaqComponent} from "./faq/faq.component";
import {LandingSellerComponent} from "./landing-seller/landing-seller.component";
import {LandingBuyerComponent} from "./landing-buyer/landing-buyer.component";

export const routes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'seller',
    component: LandingSellerComponent
  },
  {
    path: 'buyer',
    component: LandingBuyerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class LandingRoutingModule {
}
