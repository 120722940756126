import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root',
})

export class CredentialsService {
    private readonly baseUrl: string = environment.API_BASE_URL;
    constructor(private http: HttpClient) {
    }

    public cleanAuthStorage(): void {
        localStorage.removeItem('jwt');
    }

    public isLoggedIn(): boolean {
        return !!localStorage.getItem('jwt');
    }

    public isTokenExpired(): boolean {
        const token = localStorage.getItem('jwt');
        if (!token) return true;
        const tokenPayload = JSON.parse(atob(token.split('.')[1]));
        const expirationDate = new Date(tokenPayload.exp * 1000);
        return expirationDate < new Date();
    }

    async login(email: string, password: string, rememberMe: boolean): Promise<void> {
        const response = this.http.post<{ token: string }>(`${this.baseUrl}/credentials`, {
            email,
            password,
            rememberMe
        });
        const result = await lastValueFrom(response);
        localStorage.setItem('jwt', result.token);
    }

    async requestPasswordReset(email: string): Promise<void> {
        const response = this.http.post<void>(`${this.baseUrl}/credentials/reset`, {email});
        return await lastValueFrom(response);
    }

    async resetPassword(code: string, newPassword: string): Promise<void> {
        const response = this.http.post<void>(`${this.baseUrl}/credentials/reset/${code}`, {password: newPassword});
        return await lastValueFrom(response)
    }

    async deleteCredentials(): Promise<any> {
        const response = this.http.delete(`${this.baseUrl}/credentials`);
        return await lastValueFrom(response)
    }
}
