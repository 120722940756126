<ngx-spinner bdColor = "rgb(255,255,255)" size = "medium" color = "#024c44" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p class="secondary-color" > Caricamento... </p></ngx-spinner>
<div class="vh-100" *ngIf="!ready"></div>
<div class="d-flex flex-column p-md-4 p-2" *ngIf="ready">
    <div class="d-flex flex-nowrap" style="transform: translateY(0.3rem)">
        <button (click)="goTo('dashboard')" class="btn btn-link font12 me-auto" i18n id="profile-goToHome"
                style="padding-left: 0rem; padding-bottom: 0rem">
            Torna alla home
        </button>
    </div>

    <div class="title" i18n>Il tuo profilo</div>
    <label class="fw-semibold mt-3" i18n>Nome</label>
    <input [disabled]="true" [ngModel]="consumer.firstName" class="form-control">
    <label class="fw-semibold mt-3" i18n>Cognome</label>
    <input [disabled]="true" [ngModel]="consumer.lastName" class="form-control">
    <label class="fw-semibold mt-3" i18n>Indirizzo email</label>
    <input [disabled]="true" [ngModel]="consumer.email" class="form-control">
    <div class="d-flex flex-column mt-3" style="position:relative">
        <i (click)="editing()"
           [ngClass]="edited? 'ph-check-circle t-success':(checkNumber()? (!edit?'ph-pencil t-success':'ph-floppy-disk t-success'): 'text-danger ph-warning-circle')"
           class="ph icon font25"></i>
        <label [ngClass]="checkNumber()? (edit? 't-success':''):'text-danger'" class="fw-semibold" i18n>Numero di
            cellulare</label>
        <!--label class="number" style="margin-left: calc(0% + 11px)!important;">+39</label-->
        <input [(ngModel)]="consumer.phone" [disabled]="!edit" [maxlength]="13" class="form-control phone">
        <label *ngIf="!checkNumber()" class="text-danger ms-auto font12 mt-1" i18n>Numero di cellulare non
            valido!</label>
        <label *ngIf="checkNumber()&&edited" class="opacity-50 ms-auto font12 mt-1" i18n>
            Numero di cellulare aggiornato con successo!</label>
    </div>
    <label class="fw-semibold mt-3" i18n>Codice Fiscale</label>
    <input [disabled]="true" [ngModel]="consumer.tin" class="form-control text-uppercase">

    <hr>

    <div class="title mb-4" i18n>Impostazioni</div>

    <div (click)="goTo('profile','terms-and-condition')" class="d-flex flex-nowrap" style="cursor:pointer">
        <i class="ph-bold ph-lock me-2 font20 tertiary-color my-auto"></i>
        <div class="fw-semibold" i18n>Termini e condizioni</div>
        <div class="ph-bold ph-caret-right ms-auto font20 secondary-color"></div>
    </div>

    <div (click)="goTo('profile','operations-archive')" class="d-flex flex-nowrap mt-4" style="cursor:pointer">
        <i class="ph-bold ph-folder me-2 font20 tertiary-color my-auto"></i>
        <div class="fw-semibold" i18n>Archivio operazioni</div>
        <div class="ph-bold ph-caret-right ms-auto font20 secondary-color"></div>
    </div>

    <button (click)="openModal(quitAnyway, leave)" class="btn agos-outline-button mx-auto my-5" i18n id="profile-disconnect" style="width: 14rem!important;">
        Disconnetti profilo
    </button>
</div>

<ng-template #quitAnyway>
    <div class="p-4 d-flex flex-column">
        <i (click)="closeModal()" class="ph ph-x ms-auto title" style="position: absolute!important;  cursor: pointer!important;  margin-top: -15px!important;margin-left: calc(100% - 70px) !important;"></i>
        <div class="d-flex flex-nowrap">
            <i class="ph-bold ph-info title font20 me-2"></i>
            <div class="secondary-color mt-2 fw-semibold font20" i18n id="profile-quitPage-anyway">
                Attenzione! Se lasci questa pagina, tutte le modifiche andranno perse. Vuoi continuare le modifiche?
            </div>
        </div>
        <hr>
        <div class="d-flex flex-nowrap">
            <button  (click)="closeModal()" id="profile-dont-quit" class="agos-outline-button btn" i18n>Continua</button>
            <button (click)="disconnect()" id="profile-quit-anyway" class="agos-button btn ms-auto" i18n>Esci</button>
        </div>
    </div>
</ng-template>


<ng-template #leave>
    <div class="p-4 d-flex flex-column">
        <i (click)="closeModal()" class="ph ph-x ms-auto title" style="position: absolute!important;  cursor: pointer!important;  margin-top: -15px!important;margin-left: calc(100% - 70px) !important;"></i>
        <div class="d-flex flex-nowrap">
            <i class="ph-bold ph-info title font20 me-2"></i>
            <div class="secondary-color mt-2 fw-semibold font20" i18n id="profile-quitPage">
                Vuoi uscire dal tuo profilo?
            </div>
        </div>
        <div>Se esci dal tuo profilo dovrai inserire le tue credenziali per effettuare nuovamente l’accesso.</div>
        <hr>
        <div class="d-flex flex-nowrap">
            <button (click)="disconnect()" id="profile-quit" class="agos-button btn mx-auto" i18n>Continua</button>
        </div>
    </div>
</ng-template>
