import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

    constructor(private router: Router,
                private spinnerService: NgxSpinnerService) {
    }

    async ngOnInit() {
        await this.router.navigate(['not-found'])
        await this.spinnerService.hide()
    }

    public async goBack() {
        await this.router.navigate(['landing'])
    }

}
