<div class="agos-background w-100">
    <div class="d-flex flex-wrap p-md-4 p-3 w-100 container-md">
        <img (click)="goTo()" height="30" src="../../../assets/agos-logo.png"
             style="cursor: pointer; pointer-events: auto!important;" width="79">
        <img class="ms-auto my-auto" height="22" src="../../../assets/flowpay-logo.png" width="126"/>
    </div>
</div>
<div class="agos-secondary-background w-100">
    <div *ngIf="help" class="container-md d-flex flex-wrap">
        <button (click)="iNeedHelp(requestHelp)" [ngClass]="!logged? 'ms-auto':'me-auto'" class="btn btn-link"
                id="navbar-iNeedHelp" style="padding-left: 0rem">Hai bisogno di aiuto?
        </button>
        <i (click)="goTo('profile')" *ngIf="logged" class="ph-bold ms-auto ph-user t-success class my-auto font20 me-3"
           style="cursor: pointer"></i>
    </div>
</div>

<ng-template #requestHelp>
    <div class="p-4 rounded-3 d-flex flex-column" style="border: 1px dotted black">
        <div *ngIf="!requestSend" class="d-flex flex-nowrap secondary-color">
            <i class="ph-bold ph-question mt-1 font25 me-1" style="color: #024C44"></i>
            <span class="my-auto fw-semibold font20">Hai bisogno di aiuto?</span>
            <i (click)="closeModal()" class="ph ph-x ms-auto font25 opacity-50" style="cursor: pointer"></i>
        </div>
        <div *ngIf="requestSend" class="d-flex flex-nowrap secondary-color">
            <i class="ph-bold ph-check-circle mt-1 font25 me-1" style="color: #024C44"></i>
            <span class="my-auto fw-semibold font20">Grazie, la tua richiesta di contatto è stata inviata!</span>
            <i (click)="closeModal()" class="ph ph-x ms-auto mt-1 font25 opacity-50" style="cursor: pointer"></i>
        </div>

        <div *ngIf="!requestSend" class="mt-2" i18n>
            Consulta la nostra
            <a (click)="goTo('dashboard/faq')" style="cursor:pointer!important;text-decoration: underline!important;">
                pagina delle FAQ</a> oppure seleziona la motivazione della richiesta e ti contatteremo il prima
            possibile.
        </div>
        <label *ngIf="!requestSend" class="fw-semibold mt-3">Motivazione del contatto</label>
        <div *ngIf="!requestSend" class="form-floating mt-3">
            <select [(ngModel)]="ticket.reason" [disabled]="requestSend" class="form-control agos-form" id="reason"
                    placeholder="Motivazione del contatto">
                <option disabled>Motivazione del contatto</option>
                <option *ngFor="let reason of reasons" [value]="reason">
                    <span *ngIf="ReasonEnum.payment_method===reason">Metodo di pagamento</span>
                    <span *ngIf="ReasonEnum.insert_or_modify_data===reason"> Inserimento/Modifica dati</span>
                    <span *ngIf="ReasonEnum.price_management ===reason"> Gestione prezzo</span>
                    <span *ngIf="ReasonEnum.forward_funds ===reason">Fondi Sblocco</span>
                    <span *ngIf="ReasonEnum.homebanking_login ===reason">Accesso Banking Home</span>
                    <span *ngIf="ReasonEnum.payment_management ===reason">Gestione del pagamento</span>
                    <span *ngIf="ReasonEnum.other ===reason"> Altra motivazione</span>
                </option>
            </select>
            <label class="fw-semibold" for="reason" i18n>*Motivazione del contatto</label></div>
        <textarea *ngIf="requestSend" [(ngModel)]="ticket.description" class="w-100 bg-white d-flex flex-wrap w-100 p-2 my-4 mb-3"
                  maxlength="505" name="text" placeholder="*Descrivi qui brevemente il problema" rows="5"></textarea>
        <div *ngIf="requestSend" class="mt-2" i18n>
            il servizio è attivo dal lunedì al venerdì, dalle ore 8:30 alle ore 21:00, il sabato dalle ore 9:00 alle ore
            18:00
        </div>
        <hr *ngIf="!requestSend">
        <button (click)="sendRequest()" *ngIf="!requestSend" [disabled]="!ticket.reason" class="agos-button mx-auto btn"
                i18n
                id="navbar-sendRequest">Conferma
        </button>
    </div>
</ng-template>
