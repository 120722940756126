import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-main-landing',
    templateUrl: './main-landing.component.html',
    styleUrls: ['./main-landing.component.css']
})
export class MainLandingComponent implements AfterViewInit, OnInit {
    public amount: number = 5000
    public installments: number = 12
    public howItWorks: string[] = [
        $localize`L’acquirente e il venditore del veicolo si registrano al servizio in meno di un minuto`,
        $localize`L’acquirente versa l’importo concordato sul conto FlowPay in tutta sicurezza`,
        $localize`L’acquirente e il venditore effettuano il passaggio di proprietà del veicolo`,
        $localize`Il venditore incassa l’importo una volta che l’acquirente ne autorizza il trasferimento`,
    ]
    public whySignIn = [{
        title: $localize`Denaro garantito al 100%`,
        icon: `../../../assets/landing/money-hand.png`,
        text: [
            $localize`<div class="d-flex flex-nowrap"><div class="me-2">•</div><div><b>Rimborso garantito per chi acquista</b> se la compravendita non va a buon fine</div></div>`,
            $localize`<div class="d-flex flex-nowrap"><div class="me-2">•</div><div><b>Incasso garantito per chi vende</b> se la compravendita va a buon fine</div></div>`
        ]
    },
        {
            title: $localize`No esposizione a truffe`,
            icon: `../../../assets/landing/shield-check.png`,
            text: [
                $localize`<div class="d-flex flex-nowrap"><div class="me-2">•</div><div>Trasferimento dell’importo a chi vende <b>solo a passaggio di proprietà avvenuto</b></div></div>`,
                $localize`<div class="d-flex flex-nowrap"><div class="me-2">•</div><div>Certezza della disponibilità del denaro da chi acquista <b>prima del passaggio di proprietà</b></div></div>`
            ]
        }
    ]

    constructor(
        private router: Router,
        private renderer: Renderer2,
        private elRef: ElementRef) {
    }

    public async goTo(root: string) {
        await this.router.navigate(['landing', root])
    }

    ngAfterViewInit() {
        this.elRef.nativeElement.ownerDocument.body.scrollTop = 0;
    }

    ngOnInit() {
    }

}
