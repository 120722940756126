import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {FooterComponent} from './shared/footer/footer.component';
import {AppRoutingModule} from "./app-routing.component";
import {NotFoundComponent} from './shared/not-found/not-found.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {ProfileComponent} from './components/profile/profile.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {GenericErrorComponent} from './shared/generic-error/generic-error.component';
import {InterceptorModule} from "./core/interceptor/interceptor.module";
import {NgxSpinnerModule} from "ngx-spinner";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {LandingModule} from "./components/landing/landing.module";

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        NotFoundComponent,
        ProfileComponent,
        GenericErrorComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        NgxSpinnerModule,
        BrowserModule,
        AppRoutingModule,
        InterceptorModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        NgbModule,
        NgxSpinnerModule,
        LandingModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
