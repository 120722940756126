import {Component, HostListener, OnInit, TemplateRef} from '@angular/core';
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ReasonEnum, TicketLogged} from "../../core/model/ticket.model";
import {TicketService} from "../../core/service/ticket.service";
import {CredentialsService} from "../../core/service/credentials.service";
import {ViewportScroller} from "@angular/common";
import {NeedHelp, OtpError} from "../../core/model/socket.model";
import {SocketService} from "../../core/service/socket.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    public logged: boolean = false
    public reasons: ReasonEnum[] = []
    public requestSend: boolean = false
    public help: boolean = true
    public ticket: TicketLogged = {}

    constructor(
        private router: Router,
        private ticketService: TicketService,
        private modalService: NgbModal,
        private loginService: CredentialsService,
        private view: ViewportScroller,
        private socketService: SocketService) {
    }

    public async goTo(root?: string) {
        this.view.scrollToPosition([0, 0]);
        this.modalService.dismissAll()
        if (root != 'assistance') this.help = true
        if (root) await this.router.navigate([root])
        else if (this.logged) await this.router.navigate(['dashboard'])
        else await this.router.navigate(['landing'])
    }


    @HostListener('window:popstate', ['$event'])
    unloadNotification() {
        this.help = true
    }


    public async iNeedHelp(template: TemplateRef<any>) {
        this.requestSend = false
        let component = this.router.url.split('/')[1]

        if (component == "onboarding") {
            component = component + "-" + localStorage.getItem("step")
        }
        if (!this.logged) {
            const message: NeedHelp = {
                needHelp: {
                    component: "primeweb-" + component
                }
            };
            this.socketService.send(message);
            const socket = localStorage.getItem('socketSession');
            window.location.href = `https://www.agos.it/landing/c2c/form-ricontatto?_s=${window.location.href}&_eid=${socket}`
        }
        else {
            const message: NeedHelp = {
                needHelp: {
                    component: component
                }
            };
            this.socketService.send(message);
            this.modalService.open(template, {centered: true})
        }
    }

    ngOnInit(): void {
        this.logged = this.loginService.isLoggedIn()
        this.reasons = Object.values(ReasonEnum);
        const url = window.location.href;
        this.help = !url.includes('assistance') //FIXME &&!url.includes('bankaccount') // beh, l'ho scritto tempo fa, onestamente non ricordo :)
    }

    public closeModal() {
        this.modalService.dismissAll()
        this.reasons = Object.values(ReasonEnum);
        this.requestSend = false
    }

    public async sendRequest() {
        this.requestSend = true
        try {
            await this.ticketService.createTicket(this.ticket)
            this.reasons = Object.values(ReasonEnum);
        } catch (e) {
            window.alert(e)
        }
    }

    protected readonly ReasonEnum = ReasonEnum;
}
