<div class="wallpaper-box d-flex flex-column p-4">
    <div class="font25" i18n>Inserisci l’importo che desideri richiedere</div>
    <div class=" d-flex flex-nowrap my-4">
        <div class="d-flex flex-column w-50">
            <div class="fw-semibold font20 mb-3" i18n>Importo</div>

        </div>
        <div class="d-flex flex-column w-50">
            <input class="form-control ms-auto rounded-4" id="inputEuro" [(ngModel)]="amount" style="width: 10rem" type="text">
        </div>
    </div>
    <a href="https://finanziamenti.agos.it/form/start/d5aa9578-75f2-4b50-94c8-ca24e44dead7?broker=CZ" target="_blank"
       class="text-decoration-none ms-auto">
        <button class="btn agos-outline-button " id="landing-seller-calculateCommission" i18n>Calcola subito</button>
    </a>
</div>