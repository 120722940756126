<div class="d-flex flex-wrap p-md-4 p-3">
    <button (click)="goBack()" class="btn btn-link" i18n id="FAQ-goBack">Indietro</button>
    <input class="form-control rounded-5 mb-4" i18n-placeholder="@@placeholderFAQsearch" placeholder="Cerca..." [(ngModel)]="text" (input)="search(text)">
    <div class="accordion w-100" id="accordionFAQ">
        <div *ngFor="let f of filteredFAQ; let i = index" class="accordion-item  w-100 my-4">
            <h2 id='{{"faq_"+ f.question}}' class="accordion-header">
                <button [attr.aria-controls]="'collapse'+i" [attr.aria-expanded]="false"
                        [attr.data-bs-target]="'#collapse'+i" class="accordion-button collapsed font25 flex-fill"
                        data-bs-toggle="collapse" id='{{"faq_"+ f.question}}' style="padding-left: 0rem" type="button">
                    <span id='{{"faq_"+ f.question}}' class="me-2 font25 fw-semibold" style="color:#024C44">{{f.question}}</span>
                </button>
            </h2>
            <div [attr.aria-labelledby]="'faq_'+f.question" [attr.data-bs-parent]="'#accordionFAQ'" [id]="'collapse'+i"
                 class="accordion-collapse collapse">
                <div class="accordion-body opacity-50" style="padding-left:0rem" [innerHTML]="f.answer"></div>
            </div>
        </div>
    </div>
</div>

